<!--  -->
<template>
  <div class="pageWrapper overFlowWrapper">
    <van-form ref="formRef" style="margin-top:10px;overflow:scroll">
      <div class="problemInnerWrapper">
        <div class="titleBar" style="margin-bottom: 10px">
          支持情况
        </div>
        <div class="singleDetail">
          <div class="detailContent">
            <van-field
              :disabled="!isEditing"
              v-model="currentProblem.supportContent"
              type="textarea"
              name="支持情况"
              placeholder="支持情况"
              :rules="[{ required: true, message: '请填写支持情况' }]"
            />
          </div>
        </div>

        <div class="titleBar">
          现场照片
        </div>
        <div class="uploadWrapper">
          <div class="picUpload">
            <van-uploader
              v-model="fileList"
              :multiple="false"
              :before-read="beforeRead"
              :after-read="afterRead"
              @delete="beforeDelete"
              v-if="isEditing"
            >
              <!-- <template #preview-cover="{ file }">
                                <div class="preview-cover van-ellipsis">{{ file.name }}</div>
                            </template> -->
            </van-uploader>
            <div class="picsReview" v-else>
              <van-image
                width="5rem"
                height="5rem"
                :src="item.imageUrl"
                v-for="item in images"
                :key="item.imageId"
                style="margin-right: 5px"
              />
            </div>
          </div>
        </div>

        <div class="singleDetail" style="margin-bottom:80px">
          <van-checkbox v-model="currentProblem.notify" :disabled="!isEditing"
            >是否告知主管领导</van-checkbox
          >
        </div>
      </div>
      <div
        class="bottomButtonWrapper"
        style="bottom:0;height:50px;display:flex;align-"
      >
        <van-button
          @click="handleSubmit"
          v-if="isEditing"
          class="bottomButton"
          plain
          type="primary"
          size="small"
          style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  UploadPic,
  addMeetingSupport,
  getMeetingProgressDetails
} from "@/api/meetingList";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      currentProblem: {
        imageIds: [],
        supportContent: "",
        workorderId: 0,
        notify: false
      },
      fileList: [],
      images: []
    };
  },
  //监听属性 类似于data概念
  computed: {
    currentMeeting() {
      return this.$store.state.currentMeeting.meeting;
    },
    currentScheduleId() {
      return this.$store.state.currentMeeting.scheduleId;
    },
    isEditing() {
      return this.$store.state.currentMeeting.isEditing;
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getMeetingProgressDetails() {
      getMeetingProgressDetails({ scheduleId: this.currentScheduleId })
        .then(res => {
          if (res.code === 10000 && res.httpStatus === 200) {
            console.log(res.data);
            this.currentProblem.supportContent = res.data.supportContent;
            this.currentProblem.notify = res.data.notify;
            this.images = res.data.images;
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        })
        .catch(error => {
          console.log(error);
          this.$notify({ type: "warning", message: error.message });
        });
    },
    handleSubmit() {
      console.log("submit", this.currentProblem);

      this.$refs.formRef
        .validate()
        .then(() => {
          this.currentProblem.workorderId = this.currentMeeting.workorderId;
          addMeetingSupport(this.currentProblem)
            .then(res => {
              if (res.code === 10000) {
                this.$notify({ type: "success", message: "操作成功" });
                this.$store.commit("SetNavBarTitle", "会议工单");
                this.$router.push("/meetingList");
              } else {
                this.$notify({ type: "warning", message: res.message });
              }
            })
            .catch(error => {
              this.$notify({ type: "warning", message: error.message });
            });
        })
        .catch(() => {});
    },
    beforeRead(file) {
      file.index = this.fileList.length;
      return true;
    },
    beforeDelete(file) {
      this.currentProblem.imageIds = this.currentProblem.imageIds.filter(
        (item, index) => {
          return index !== file.file.index;
        }
      );
      //   重新排index
      this.fileList = this.fileList.map((item, index) => {
        item.file.index = index;
        return item;
      });
    },
    afterRead(file) {
      UploadPic(file.file)
        .then(res => {
          if (res.code === 10000 && res.httpStatus === 200) {
            this.currentProblem.imageIds.push(res.data.imageId);
            this.$notify({ type: "success", message: "上传成功" });
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (!this.isEditing) {
      this.getMeetingProgressDetails();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
.singleDetail {
  background-color: #fff;
  border-radius: 3px;
  padding: 5px;
  padding-left: 10px;
  margin-bottom: 15px;
}
.singleDetailTitle {
  border-left: 2px solid #2bb7b3;
  padding-left: 5px;
  font-size: 0.6rem;
}
.detailContent .van-cell {
  padding-left: 5px;
}
.radioFloatWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
}
.overFlowWrapper {
  height: 100%;
  overflow: scroll;
}
.uploadWrapper {
  background-color: #fff;
  margin: 10px 0;
  border-radius: 3px;
  padding: 0.5rem;
}

.uploadWrapper .van-uploader__upload {
  margin: 0;
}
</style>
